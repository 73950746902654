import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const useReservationHeroQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 1638 }) {
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  height: 600
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }
  `)
  return {
    imageData:
      data.wpPage.featuredImage.node.localFile.childImageSharp.gatsbyImageData,
    content: data.wpPage.content,
  }
}

export default useReservationHeroQuery
