import React from "react"
import useAboutQuery from "../../hooks/useAboutQuery"
import { Wrapper, AboutImage } from "./About.styles"

const About = () => {
  const { content, url } = useAboutQuery()
  return (
    <Wrapper>
      <AboutImage image={url} />
      <div className="about-text">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </Wrapper>
  )
}

export default About
