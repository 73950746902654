import { graphql, useStaticQuery } from "gatsby"

const useAboutQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(databaseId: { eq: 47 }) {
          content
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `
  )
  return {
    content: data.wpPage.content,
    url: data.wpPage.featuredImage.node.localFile.publicURL,
  }
}

export default useAboutQuery
