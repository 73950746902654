import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  a {
    padding: 0;
  }
  img {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 2fr 1fr;
  }
`
export const TextWrapper = styled.div`
  padding: 20px;
`
