import { graphql, useStaticQuery } from "gatsby"

const useCarouselImageQuery = () => {
  const data = useStaticQuery(graphql`
    fragment carouselImage on WpMediaItem {
      localFile {
        publicURL
      }
    }
    query {
      wpPage(databaseId: { eq: 47 }) {
        ACF_HomePage {
          heroimage1 {
            altText
            ...carouselImage
          }
          heroimage2 {
            altText
            ...carouselImage
          }
          heroimage3 {
            altText
            ...carouselImage
          }
        }
      }
    }
  `)
  const transformedData = new Array(3).fill("").map((element, i) => ({
    url: data.wpPage.ACF_HomePage[`heroimage${i + 1}`].localFile.publicURL,
    altText: data.wpPage.ACF_HomePage[`heroimage${i + 1}`].altText,
  }))
  return transformedData
}

export default useCarouselImageQuery
