import React from "react"
import useQuoteQuery from "../../hooks/useQuoteQuery"
import { Wrapper, Content } from "./Quote.styles"
import QuoteImg from "../../images/quote.svg"
const Quote = () => {
  const { citat1Author, citat1Text } = useQuoteQuery()
  return (
    <Wrapper>
      <Content>
        <img src={QuoteImg} alt="quote" />
        <h6>{citat1Text}</h6>
        <p>{citat1Author}</p>
      </Content>
    </Wrapper>
  )
}

export default Quote
