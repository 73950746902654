import styled from "styled-components"
import { colors } from "../../colors.css"

export const Wrapper = styled.div`
  background: #f2ede7;
  text-align: center;
  padding: 40px 0;
  color: ${colors.active};
  p {
    margin-top: 1.5rem;
    font-style: italic;
  }

  img {
    display: block;
    max-width: 40px;
    margin: 20px auto 40px auto;
  }
`

export const Content = styled.div`
  max-width: 1080px;
  margin: 0 auto;

  h6 {
    font-size: 1rem;
    color: inherit;
    font-family: prata;
  }

  @media screen and (min-width: 768px) {
    h6 {
      font-size: 1.5rem;
    }
  }
`
