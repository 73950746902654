import * as React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import Hero from "../components/Hero/Hero"
import CTAArea from "../components/CTAArea/CTAArea"
import Quote from "../components/Quote/Quote"
import About from "../components/About/About"
import Carousel from "../components/Carousel/Carousel"
import FiveStar from "../components/FiveStar/FiveStar"
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Carousel />
    <CTAArea />
    <Quote />
    <About />
    <FiveStar />
  </Layout>
)

export default IndexPage
