import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { colors } from "../../colors.css"
import "../../fonts/font.css"
import { Link } from "gatsby"

export const StyledImg = styled.div`
  background: ${({ url }) => `url(${url}) `};
  background-position: center;
  text-align: center;
  background-size: cover;
  repeat: no-repeat;
  width: 100%;
  height: 100%;
  transition: all 1s;
`
export const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 110px);
  min-height: 600px;
`

export const CtaWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  @media screen and (max-width: 762px) {
    flex-direction: column;
    bottom: 50px;
    width: 100%;
  }
`

export const CtaButton = styled(Link)`
  font-family: "PeachExquisiteOpti", Helvetica;
  min-width: 200px;
  margin: 10px;
  border-radius: 10px;
  border: none;
  font-size: 1.5rem;
  padding: 0.5rem 0.7rem;
  cursor: pointer;
  background-color: ${colors.active};
  color: #fff;
  transition: color 0.3s;
  :hover {
    color: ${colors.active};
    background-color: #fff;
  }
`

export const DotsWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 15px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  z-index: 1;
`
export const DescriptionWrapper = styled.div`
  font-family: "PeachExquisiteOpti", Helvetica;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 1px solid white;
  background: ${colors.background};
  transform: translate(-50%, -50%);
  justify-content: center;
  z-index: 1;
  font-size: 34px;
  color: ${colors.active};
  padding: 0.25rem 0.5rem;
  h1,
  p {
    font-family: inherit;
    color: inherit;
    text-transform: capitalize;
  }
  p {
    text-align: center;
  }
`

export const Dot = styled.div`
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  &.active {
    background: ${colors.active};
  }
`
