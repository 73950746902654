import React from "react"
import { TextWrapper, Wrapper } from "./FiveStar.styles"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import DivWrapper from "../DivWrapper/DivWrapper"
import { Button } from "@mui/material"

const FiveStar = () => {
  const data = useStaticQuery(graphql`
    query FiveStarImageQuery {
      wpPage(databaseId: { eq: 47 }) {
        ACF_HomePage {
          fiveStarRatingContent
          fiveStarRatingUrl
          fiveStarRating {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  `)
  console.log(data)

  return (
    <DivWrapper>
      <Wrapper>
        <GatsbyImage
          image={
            data.wpPage.ACF_HomePage.fiveStarRating.localFile.childImageSharp
              .gatsbyImageData
          }
          alt="Five star tajmhal buffet image"
        />
        <TextWrapper>
          {data.wpPage.ACF_HomePage.fiveStarRatingContent}{" "}
          <Button
            component="a"
            href={data.wpPage.ACF_HomePage.fiveStarRatingUrl}
            target="_blank"
            rel="no-opener no-referrer"
          >
            Read More ...
          </Button>
        </TextWrapper>
      </Wrapper>
    </DivWrapper>
  )
}

export default FiveStar
