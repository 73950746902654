import React, { useEffect, useState } from "react"
import useCarouselImageQuery from "../../hooks/useCarouselImageQuery"
import {
  CtaButton,
  CtaWrapper,
  DescriptionWrapper,
  Dot,
  DotsWrapper,
  StyledImg,
  Wrapper,
} from "./Carousel.styles"

const ImageSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const gotoNextSlide = () => {
    setCurrentIndex(curr => (curr === slides.length - 1 ? 0 : curr + 1))
  }

  useEffect(() => {
    const interval = setInterval(gotoNextSlide, 10000)
    return () => clearInterval(interval)
  }, [])

  const gotoSlide = slideIndex => {
    setCurrentIndex(slideIndex)
  }

  return (
    <Wrapper>
      <StyledImg url={slides[currentIndex].url}>
        <DescriptionWrapper>
          <h1>Tajmahal Buffet Grantham</h1>
          <p>Buffet Lounge</p>
        </DescriptionWrapper>

        <CtaWrapper>
          <CtaButton to="/reservation">Book a table</CtaButton>
          <CtaButton to="https://orderonline-tajmahalbuffet.co.uk">Order Online</CtaButton>
        </CtaWrapper>
        <DotsWrapper>
          {slides.map((slide, i) => (
            <Dot
              key={i}
              onClick={() => gotoSlide(i)}
              className={currentIndex === i ? "active" : ""}
            ></Dot>
          ))}
        </DotsWrapper>
      </StyledImg>
    </Wrapper>
  )
}

const Carousel = () => {
  const data = useCarouselImageQuery()
  return <ImageSlider slides={data} />
}

export default Carousel
