import styled from "styled-components"
import { colors } from "../../colors.css"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: #ddd;
  width: 100%;
  text-align: center;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  .about-text {
    padding: 40px 20px 20px 40px;
    display: flex;
    justify-content: center;
    .about-text-inner {
      max-width: 500px;
      font-family: "Effra Regular", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
      font-size: 1rem;
      box-shadow: 10px 10px 8px #888888;
      background: #f2ede7;
      padding: 1rem;
    }
    .about-heading {
      color: ${colors.active};
    }

    @media screen and (max-width: 768px) {
      padding: 20px;
    }
  }
`

export const AboutImage = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  min-height: 300px;
`
